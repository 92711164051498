<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Employee Salaries</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                  <li class="breadcrumb-item active">
                    <router-link :to="{name: 'salary-slabs'}">
                        <span>Salary Slabs</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active"   v-if="slab_options.length>1" aria-current="page">{{ getSlab() }}</li>
         
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
                <b-row>
                    <b-col lg="6" class="mb-1rem">
                        <div class="pb-2">
                            <h4  v-if="slab_options.length>1">Slab: {{getSlab()}}</h4>
                        </div>
                    </b-col>
                    <b-col lg="6" class="mb-1rem text-right">
                        <b-form-group
                            label="Search"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            label-for="filterInput"
                            class="mb-0"
                            style="font-weight:bold; font-size:1rem !important;"
                        >
                            <b-input-group size="md">
                                <b-form-input
                                    v-model="filter"
                                    type="search"
                                    id="filterInput"
                                    placeholder="Type Employee Name to Search"
                                    v-on:keyup.enter="onFiltered"
                                    @search="clearSearch"
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-button
                                        :disabled="!filter"
                                        @click="onFiltered"
                                        class="btn btn-primary"
                                    >Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                
            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                @filtered="onFiltered"
                responsive
            >
                 <template v-slot:cell(emp_id)="row">
                    {{row.item.employee.full_name}} [IES-{{row.item.emp_id}}] 
                 </template>
            </b-table>

            <b-row align-h="between" v-if="total_rows">
                <b-col lg="4" class="p-3">
                    <div class="row input-group">
                        <div class="input-group-append my-2">Showing</div>
                        <div class="mx-1em">
                            <select
                                class="form-control"
                                v-model="urlBody.per_page"
                                @change="changePageCount"
                            >
                                <option
                                    v-for="option in pageOptions"
                                    :key="option.index"
                                >{{option}}</option>
                            </select>
                        </div>
                        <div class="input-group-append my-2" v-if="total_rows>urlBody.per_page">of {{total_rows}}</div>
                    </div>
                </b-col>
                <b-col lg="4" class="p-3">
                    <nav aria-label="Page navigation" class="justify-content-center">
                        <ul class="pagination float-right">
                            <li class="page-item">
                                <span
                                    class="page-link"
                                    v-on:click="pagination(urlBody.page - 1)"
                                    v-if="urlBody.page > 1"
                                    style="cursor:pointer"
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-else
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                            </li>
                            <li class="page-item">
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-if="urlBody.page == lastPage"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                                <span
                                    class="page-link"
                                    v-else
                                    v-on:click="pagination(urlBody.page +1)"
                                    style="cursor:pointer"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                            </li>
                        </ul>
                    </nav>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                sort_by: "",
                order: "",
                page: 1,
                per_page: 10,
                slab_id:"all",
                query:"",
            },
            api_error: "",
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "emp_id", label:"Employee", sortable: false },
                { key: "offered_gross_per_year", sortable: false },
                { key: "offered_gross_per_month", sortable: false },
                { key: "effective_from", sortable: false }
            ],
            slab_options: [
                { value: 'all', text: 'All Employees' },
            ],
            items: null,
            filter:null,
            access: false,
            alertDismissCountDown: 0,
            total_rows: 1,
            lastPage: 1,
            pageOptions: [10, 25, 50, 100],
            infoModal: {
                id: "modal-sm",
                title: "",
                content: ""
            },
            alertDismissSecs: 10,
            message:"",
            // salary_slab: {},
            page_loader: false
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_SALARY_SLABS");
        this.getSlabList();
        // this.getSalarySlab();
        this.onPageLoad();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        
        getSlab(){
            if(this.urlBody.slab_id =='all'){
                return this.slab_options["0"].text;
            }
            return this.slab_options[this.urlBody.slab_id].text;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getDefaultFilters() {
            let api_params = {
                page: this.urlBody.page,
                per_page: this.urlBody.per_page,
                sort_by: this.urlBody.sort_by,
                order: this.urlBody.order,
            };
            return api_params;
        },

        onPageLoad() {
            const api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.getDefaultFilters();
            this.setDefaultFilters();
            this.getEmployeeSalaries(api_params);
        },

        setDefaultFilters() {
            let api_params = this.deleteEmptyKeys(this.$route.query);
            this.urlBody.slab_id = api_params.slab_id
                ? api_params.slab_id
                : this.urlBody.slab_id;
            this.urlBody.sort_by = api_params.sort_by
                ? api_params.sort_by
                : this.urlBody.sort_by;
            this.urlBody.order = api_params.order
                ? api_params.order
                : this.urlBody.order;
        },

        getSlabList() {
            var query = this.getAuthHeaders();
            query['params']={};
            this.axios.get(this.$api.get_salary_slabs, query).then(response => {
                let result = response.data.data;
                result.forEach(slab_options => {
                    this.slab_options.push({
                        value: slab_options.id,
                        text: slab_options.name
                    });
                });
            }).catch(err => {
                if (err) {
                    this.api_error = default_error.server_error;
                    this.showAlert();
                }
            });
        },

        onSelection() {
            var query =this.deleteEmptyKeys(this.urlBody);
            this.getEmployeeSalaries(query);
        },
        
        getEmployeeSalaries: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            if(!query["params"].slab_id){
                query["params"].slab_id= this.urlBody.slab_id;
            }
            this.axios.get(this.$api.get_employee_salaries_by_slab, query)
                .then(response => {
                    let result = response.data.data;
                    this.items = result.data;
                    this.urlBody.page = result.current_page;
                    this.urlBody.per_page = result.per_page;
                    this.lastPage = result.last_page;
                    this.total_rows = result.total;
                    this.api_error = "";
                    this.updateProgressBar(true);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: this.deleteEmptyKeys(params)
                        }).catch(()=>{})
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },

        changePageCount: function() {
            let newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.per_page = this.urlBody.per_page;
            this.api_error = "";
            this.getEmployeeSalaries(newQuery);
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            urlBody.page = 1;
            this.api_error = "";
            this.getEmployeeSalaries(urlBody)
        },

        clearSearch (event) {
            if (event.target.value === "") {
                if (!this.filter) {
                    this.api_error = "";
                    this.getEmployeeSalaries(this.getDefaultFilters());
                }
            }
        },

        pagination: function(pageNumber) {
            var newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.page = pageNumber;
            this.api_error = "";
            this.getEmployeeSalaries(newQuery);
        },

        info(item, index, button) {
            this.infoModal.title = `Delete Confirmation`;
            this.message = 'Are you sure you want to Delete this Employee Salary.';
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },

        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },

        onFiltered() {
            if (this.filter) {
                this.urlBody.query= this.filter;
                var query =this.deleteEmptyKeys(this.urlBody);
                this.getEmployeeSalaries(query);
            }
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>